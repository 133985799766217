import { parse, isBefore, isValid } from 'date-fns';
import { FormStepObj } from '@Vaccination/main/context/AddVaccinationsContext';
import RenderVaccineComponent from '@Vaccination/main/components/RenderVaccineComponent';

const isPastMonth = (month?: string) => {
  if (!month) return false;
  const date = parse(month, 'MM/yyyy', new Date());
  const now = new Date();
  const startOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  return isBefore(date, startOfCurrentMonth);
};

const isValidDate = (date: string) => {
  const validDateLength = date?.length === 7;
  if (!validDateLength) {
    return false;
  }
  return isValid(parse(date, 'MM/yyyy', new Date()));
};

const createVaccinationSteps = (vaccineTypes: string[]): FormStepObj[] => {
  const totalArrLength = vaccineTypes.length * 2;

  const screeningSteps = vaccineTypes.map((vaccine, id) => ({
    value: `${vaccine}-screening`,
    key: `Step ${id + 1} of ${totalArrLength} - Clinical Screening for ${vaccine}`,
    id: id + 1,
    active: id === 0,
    component: RenderVaccineComponent({ vaccineType: vaccine, componentType: 'screening' }),
  }));

  const deliverySteps = vaccineTypes.map((vaccine, id, arr) => ({
    value: `${vaccine}-delivery`,
    key: `Step ${arr.length + 1 + id} of ${totalArrLength} - Delivery of ${vaccine}`,
    id: arr.length + 1 + id,
    active: false,
    component: RenderVaccineComponent({ vaccineType: vaccine, componentType: 'delivery' }),
  }));

  return [...screeningSteps, ...deliverySteps];
};

const moveToNextStep = (steps: FormStepObj[]): FormStepObj[] => {
  const activeStepIndex = steps.findIndex(step => step.active);

  if (activeStepIndex <= 0 || activeStepIndex >= steps.length - 1) {
    return steps; // No change if no active step or already at last step
  }

  return steps.map((step, index) => ({
    ...step,
    active: index === activeStepIndex + 1,
  }));
};

const moveToPreviousStep = (steps: FormStepObj[]): FormStepObj[] => {
  console.log('steps', steps);
  const activeStepIndex = steps.findIndex(step => step.active);

  if (activeStepIndex <= 0) {
    return steps; // No change if no active step or already at last step
  }

  return steps.map((step, index) => ({
    ...step,
    active: index === activeStepIndex - 1,
  }));
};

export { isPastMonth, isValidDate, createVaccinationSteps, moveToNextStep, moveToPreviousStep };
