import { useContext } from 'react';
import {
  AddVaccinationsContext,
  AddVaccinationsContextType,
} from '@Vaccination/main/context/AddVaccinationsContext';

export const useAddVaccinations = (): AddVaccinationsContextType => {
  const context = useContext(AddVaccinationsContext);
  if (!context) {
    throw new Error('useAddVaccinations must be used within a AddVaccinationsProvider');
  }

  return context;
};
