import * as yup from 'yup';
import { SchemaOf, object, array, boolean, string } from 'yup';
import { ValidationErrorsMsg } from '@enums/ValidationErrors';
import { FormStep, VACCINATION_TYPE } from '@Vaccination/main/context/AddVaccinationsContext';
import { Response as PatientDemographics } from '@models/classes/PatientDetailsSideBar/PatientDemographicsResponse';

interface PatientEmergencyContact {
  firstName?: string;
  surname?: string;
  telephone?: string;
  relationship?: string;
}

export interface AddVaccinationType {
  id: string;
  consentStatus: boolean;
  vaccineTypes: string[];
  patientDemographics?: PatientDemographics;
  status?: string;
  patientEmergencyContact?: PatientEmergencyContact;
  currentStep?: string;
}

export type NewVaccination = Omit<AddVaccinationType, 'id'>;

export class AddVaccination implements Partial<AddVaccinationType> {
  consentStatus?: boolean;
  vaccineTypes?: string[];
  patientDemographics?: PatientDemographics;
  status?: string;
  patientEmergencyContact?: PatientEmergencyContact;
  currentStep?: string;

  constructor(data?: Partial<AddVaccination>) {
    this.consentStatus = data?.consentStatus;
    this.vaccineTypes = data?.vaccineTypes;
    this.patientDemographics = data?.patientDemographics;
    this.patientEmergencyContact = data?.patientEmergencyContact;
    this.status = data?.status;
    this.currentStep = data?.currentStep;
  }
}

const patientDemographicsSchema: SchemaOf<PatientDemographics> =
  yup.object() as yup.SchemaOf<PatientDemographics>;

export const addVaccinationScheme: SchemaOf<AddVaccinationType> = object().shape({
  consentStatus: boolean().required(ValidationErrorsMsg.BLANK_FIELD),
  vaccineTypes: array().when('consentStatus', {
    is: true,
    then: array().min(1, ValidationErrorsMsg.MIN_1_VALUE).required(ValidationErrorsMsg.BLANK_FIELD),
  }),
  patientDemographics: patientDemographicsSchema,
  patientEmergencyContact: object().shape({
    firstName: string().optional(),
    surname: string().optional(),
    telephone: string().optional(),
  }),
  status: string().required(),
  currentStep: string().optional(),
}) as unknown as SchemaOf<AddVaccinationType>;

export const getStepValidationScheme = (step: FormStep) => {
  switch (step) {
    case VACCINATION_TYPE:
    default:
      return addVaccinationScheme;
  }
};
