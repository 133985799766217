import {
  FormStep,
  FormStepObj,
  VaccinationFormValues,
  VaccinationType,
  PATIENT_SEARCH,
  VACCINATION_TYPE,
  DELIVERY_SCREENING,
} from '@Vaccination/main/context/AddVaccinationsContext';
import { Response as PatientDemographics } from '@models/classes/PatientDetailsSideBar/PatientDemographicsResponse';

export interface State {
  formStep: FormStep;
  formSteps?: FormStepObj[];
  formValues: VaccinationFormValues;
  availableVaccinations: VaccinationType[];
}

export type Action =
  | { type: 'SET_FORM_STEP'; payload: FormStep }
  | { type: 'SET_FORM_STEPS'; payload: FormStepObj[] }
  | { type: 'ACTIVATE_NEW_STEP'; payload: FormStepObj[] }
  | { type: 'UPDATE_FORM_VALUES'; payload: Partial<VaccinationFormValues> }
  | { type: 'SET_PATIENT'; payload: PatientDemographics }
  | { type: 'SET_AVAILABLE_VACCINATIONS'; payload: VaccinationType[] }
  | { type: 'RESET_FORM' };

export const initialState: State = {
  formStep: PATIENT_SEARCH,
  formSteps: [],
  formValues: {},
  availableVaccinations: [],
};

const formStepReducer = (state: State, payload: FormStep): State => ({
  ...state,
  formStep: payload,
});

const formStepsReducer = (state: State, payload: FormStepObj[]): State => ({
  ...state,
  formStep: DELIVERY_SCREENING,
  formSteps: payload,
});

const activateStepReducer = (state: State, payload: FormStepObj[]): State => ({
  ...state,
  formSteps: payload,
});

const updateFormValuesReducer = (state: State, payload: Partial<VaccinationFormValues>): State => ({
  ...state,
  formValues: {
    ...state.formValues,
    ...payload,
  },
});

const setPatientReducer = (state: State, payload: PatientDemographics): State => ({
  ...state,
  formValues: {
    ...state.formValues,
    selectedPatient: payload,
  },
  formStep: VACCINATION_TYPE,
});

const setAvailableVaccinationsReducer = (state: State, payload: VaccinationType[]): State => ({
  ...state,
  availableVaccinations: payload,
});

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FORM_STEP':
      return formStepReducer(state, action.payload);
    case 'SET_FORM_STEPS':
      return formStepsReducer(state, action.payload);
    case 'ACTIVATE_NEW_STEP':
      return activateStepReducer(state, action.payload);
    case 'UPDATE_FORM_VALUES':
      return updateFormValuesReducer(state, action.payload);
    case 'SET_PATIENT':
      return setPatientReducer(state, action.payload);
    case 'SET_AVAILABLE_VACCINATIONS':
      return setAvailableVaccinationsReducer(state, action.payload);
    case 'RESET_FORM':
      return initialState;
    default:
      return state;
  }
};
