import {
  Heading,
  Alert,
  Card,
  CardBody,
  CardHeading,
  SummaryList,
  Form,
  LoadingSpinner,
} from '@crx-dev/hss-react-components';
import React from 'react';
import useVaccinationHistory from '@Vaccination/main/hooks/useVaccinationHistory';

const VaccinationHistoryCard = () => {
  const { data, isLoading, isError } = useVaccinationHistory();

  const latestVaccine = data?.[0];
  if (isLoading) {
    return (
      <Form.Section>
        <LoadingSpinner height={150} />;
      </Form.Section>
    );
  }

  if (!latestVaccine || isError) {
    return null;
  }

  return (
    <Form.Section>
      <Heading>Previous Flu Vaccination Details</Heading>
      <Alert type="Error" mb="m">
        The patient has had a vaccination in the last 6 months
      </Alert>
      <Card>
        <CardBody>
          <CardHeading mb="m">{latestVaccine.vaccineName}</CardHeading>
          <SummaryList>
            <SummaryList.Item label="Manufacturer">{latestVaccine.manufacturer}</SummaryList.Item>
            <SummaryList.Item label="Date of administration">
              {latestVaccine.administeredDate}
            </SummaryList.Item>
          </SummaryList>
        </CardBody>
      </Card>
    </Form.Section>
  );
};

export default VaccinationHistoryCard;
