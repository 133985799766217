import { VACCINATION_PATHS } from '@enums/PagesPathsEnum';

const vaccinationServiceTabs = [
  { id: 'active', label: 'Active' },
  { id: 'completed', label: 'Completed' },
];

const batchServiceTabs = [
  { id: 'active', label: 'Active' },
  { id: 'finished', label: 'Finished' },
];

const batchStatuses = [
  { value: 'Active', label: 'Active' },
  { value: 'Finished', label: 'Finished' },
];

const preserveDataPaths: string[] = [VACCINATION_PATHS.ADD_VACCINATION];

const VACCINE_TYPES = [{ label: 'Flu Vac', value: 'Flu Vac' }];

export { vaccinationServiceTabs, batchStatuses, batchServiceTabs, preserveDataPaths, VACCINE_TYPES };
