import { useQuery } from '@tanstack/react-query';
import { Record } from '@models/classes/VaccinationHistory/Record';
import getVaccinationHistory from '@Vaccination/main/services/getVaccinationHistory';
import { useAddVaccinations } from '@Vaccination/main/hooks/useAddVaccinations';

const useVaccinationHistory = () => {
  const { formValues } = useAddVaccinations();
  const nhsNumber = formValues.selectedPatient?.patientDetails.nhsNumber;
  const { data, isFetching, error, isError } = useQuery<Record[]>({
    queryFn: () => getVaccinationHistory(nhsNumber!, 'FLU'),
    queryKey: ['vaccinationHistory', { nhsNumber: nhsNumber, targetDisease: 'FLU' }],
    refetchOnMount: true,
    staleTime: 60000,
    enabled: !!nhsNumber,
  });

  if (error) {
    console.error('Error error', error.message);
  }

  return { data, isLoading: isFetching, isError };
};

export default useVaccinationHistory;
