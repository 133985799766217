import React from 'react';
import { AppContainer } from '@crx-dev/hss-react-components';
import {
  DmsPagesPaths,
  FluVaccinationPagesPaths,
  HcfPagesPaths,
  NmsPagesPaths,
  PagesPaths,
  PcsPagesPaths,
  VACCINATION_PATHS,
} from '@enums/PagesPathsEnum';
import KeyCloakAuth from '@security/KeyCloakAuth';
import { CPCS, NEW_CPCS, DMS, FLUVAC, HCF, NMS, PCS, VAC } from '@src/base/roles';
import IdleTimeHandler from '../IdleTimeHandler/IdleTimeHandler';
import { useNavigate, useLocation } from 'react-router-dom-v6';

const HELP_URL = 'https://help.cegedim-healthcare.co.uk/Pharmacy_Services/Default.htm';
const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navHelper = (to: string) => ({
    to,
    onClick: (to: string) => navigate(to),
  });

  const isPFPath = () => {
    return [
      PagesPaths.MINOR_ILLNESS,
      PagesPaths.URGENT_SUPPLY,
      PagesPaths.CLINICAL_PATHWAYS_WALK_IN_PAGE,
      PagesPaths.MANUAL_REFERRAL,
      PagesPaths.REFERRALS,
      PagesPaths.REFERRAL_DETAILS,
      PagesPaths.REFERRAL_SUMMARY,
      PagesPaths.ADD_REFERRAL_PAGE,
      PagesPaths.REFERRAL_PATIENT,
    ].includes(location.pathname as PagesPaths);
  };

  const isDMSPath = () => {
    return (
      [
        DmsPagesPaths.DMS_MANUAL_REFERRAL,
        DmsPagesPaths.DMS_REFERRAL,
        DmsPagesPaths.SESSION,
        DmsPagesPaths.SUMMARY,
      ].includes(location.pathname as DmsPagesPaths) ||
      PagesPaths.DISCHARGE_MEDICINES_REFERRALS == location.pathname
    );
  };

  const isNMSPath = () => {
    return [NmsPagesPaths.NMS_EPISODE, NmsPagesPaths.NEW_MEDICINE_SERVICE].includes(
      location.pathname as NmsPagesPaths,
    );
  };

  const isFluVacPath = () => {
    return [
      FluVaccinationPagesPaths.FLU_VACCINATION_EPISODE,
      FluVaccinationPagesPaths.FLU_VACCINATION_SERVICE,
    ].includes(location.pathname as FluVaccinationPagesPaths);
  };

  const isHCFPath = () => {
    return [HcfPagesPaths.HCF_EPISODE, HcfPagesPaths.HCF_SERVICE].includes(
      location.pathname as HcfPagesPaths,
    );
  };

  const isPCSPath = () => {
    return [PcsPagesPaths.PCS_EPISODE, PcsPagesPaths.PCS_SERVICE].includes(
      location.pathname as PcsPagesPaths,
    );
  };

  const isVacPath = () => {
    return Object.values(VACCINATION_PATHS).includes(location.pathname);
  };

  const handleHelpUrl = () => {
    switch (location.pathname) {
      case NmsPagesPaths.NMS_EPISODE:
      case NmsPagesPaths.NEW_MEDICINE_SERVICE:
        return HELP_URL + '#cshid=nms';
      case HcfPagesPaths.HCF_EPISODE:
      case HcfPagesPaths.HCF_SERVICE:
        return HELP_URL + '#cshid=hypertension';
      case PcsPagesPaths.PCS_EPISODE:
      case PcsPagesPaths.PCS_SERVICE:
        return HELP_URL + '#cshid=contraception';
      case FluVaccinationPagesPaths.FLU_VACCINATION_EPISODE:
      case FluVaccinationPagesPaths.FLU_VACCINATION_SERVICE:
        return HELP_URL + '#cshid=flu';
      case PagesPaths.DISCHARGE_MEDICINES_REFERRALS:
      case DmsPagesPaths.DMS_MANUAL_REFERRAL:
      case DmsPagesPaths.DMS_REFERRAL:
      case DmsPagesPaths.SESSION:
      case DmsPagesPaths.SUMMARY:
        return HELP_URL + '#cshid=dms';
      case PagesPaths.MINOR_ILLNESS:
      case PagesPaths.URGENT_SUPPLY:
      case PagesPaths.CLINICAL_PATHWAYS_WALK_IN_PAGE:
      case PagesPaths.MANUAL_REFERRAL:
      case PagesPaths.REFERRALS:
      case PagesPaths.REFERRAL_DETAILS:
      case PagesPaths.REFERRAL_SUMMARY:
      case PagesPaths.ADD_REFERRAL_PAGE:
        return HELP_URL + '#cshid=pharmacyfirst';
      default:
        return HELP_URL;
    }
  };
  return (
    <>
      <IdleTimeHandler />
      <AppContainer.NavItem label="Dashboard" {...navHelper(PagesPaths.HOME)} />
      {(KeyCloakAuth.hasRole(CPCS) || KeyCloakAuth.hasRole(NEW_CPCS)) && (
        <AppContainer.NavItem
          label="Pharmacy First"
          {...navHelper(PagesPaths.REFERRALS)}
          isSelected={isPFPath()}
        />
      )}
      {KeyCloakAuth.hasRole(DMS) && (
        <AppContainer.NavItem
          label="Discharge Medicines"
          isNew
          {...navHelper(PagesPaths.DISCHARGE_MEDICINES_REFERRALS)}
          isSelected={isDMSPath()}
        />
      )}
      {KeyCloakAuth.hasRole(NMS) && (
        <AppContainer.NavItem
          label="NMS"
          {...navHelper(NmsPagesPaths.NEW_MEDICINE_SERVICE)}
          isSelected={isNMSPath()}
        />
      )}
      {KeyCloakAuth.hasRole(FLUVAC) && (
        <AppContainer.NavItem
          label="Flu Vaccination"
          {...navHelper(FluVaccinationPagesPaths.FLU_VACCINATION_SERVICE)}
          isSelected={isFluVacPath()}
        />
      )}
      {KeyCloakAuth.hasRole(HCF) && (
        <AppContainer.NavItem
          label="Hypertension Case-Finding"
          {...navHelper(HcfPagesPaths.HCF_SERVICE)}
          isSelected={isHCFPath()}
        />
      )}
      {KeyCloakAuth.hasRole(PCS) && (
        <AppContainer.NavItem
          label="Contraception"
          {...navHelper(PcsPagesPaths.PCS_SERVICE)}
          isSelected={isPCSPath()}
        />
      )}
      {KeyCloakAuth.hasRole(VAC) && (
        <AppContainer.NavItem
          label="Vaccinations"
          {...navHelper(VACCINATION_PATHS.MAIN)}
          isSelected={isVacPath()}
          isNew
        />
      )}
      <AppContainer.NavItem label="Help" to={handleHelpUrl()} external />
    </>
  );
};
export default SideBar;
