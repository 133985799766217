import { useFormikContext } from 'formik';
import { useLocation, Location } from 'react-router-dom-v6';
import { useEffect, useContext } from 'react';
import { AddVaccinationsContext } from '@Vaccination/main/context/AddVaccinationsContext';
import { preserveDataPaths } from '@Vaccination/utils/constants';
import { PagesPaths } from '@enums/PagesPathsEnum';

const FormikResetHandler = () => {
  const { resetForm } = useFormikContext();
  const location = useLocation();
  const { resetForm: resetContextForm } = useContext(AddVaccinationsContext);

  useEffect(() => {
    const handleLocationChange = (newLocation: Location) => {
      if (!preserveDataPaths.includes(newLocation.pathname as PagesPaths)) {
        resetForm();
        resetContextForm();
      }
    };

    handleLocationChange(location);
  }, [location, resetForm, resetContextForm]);

  return null; // This component doesn't render anything
};

export default FormikResetHandler;
