import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './base/App';
import { ApplicationStateProvider } from '@context/ApplicationState';
import { HSSComponentProvider } from '@crx-dev/hss-react-components';
import KeyCloakAuth from './security/KeyCloakAuth';
import InterceptLocumUser from './components/InterceptLocumUser/InterceptLocumUser';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import hotjarInit from '@common/analytics/Hotjar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './wdyr';
import ConfirmationModalProvider from '@components/Shared/Confirmation/ConfirmationModalProvider';

const secondsInAMinute = 60;
const millisecondsInASecond = 1000;
const minutes = +window.__RUNTIME_CONFIG__.VITE_GP_CACHE_TIME_IN_MINUTES;

const cacheTime = minutes * secondsInAMinute * millisecondsInASecond;
console.info('GP_CACHE_TIME', cacheTime);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: cacheTime,
      gcTime: cacheTime,
      refetchOnMount: false,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

hotjarInit();

const renderApp = () => {
  // TODO Refactor this part when storybook is updated
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ApplicationStateProvider>
          <HSSComponentProvider maxContentWidth={1340} primaryThemeColor="RositaBlue">
            <InterceptLocumUser>
              <ConfirmationModalProvider>
                <App />
              </ConfirmationModalProvider>
              <ToastContainer />
            </InterceptLocumUser>
          </HSSComponentProvider>
        </ApplicationStateProvider>
        <ReactQueryDevtools position="left" />
      </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

KeyCloakAuth.initKeycloak(renderApp);
