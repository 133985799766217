import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { AddVaccination, NewVaccination, AddVaccinationType } from '@Vaccination/utils/AddVaccinationScheme';
import { useLocation, Location } from 'react-router-dom-v6';
import createEditVaccine from '@Vaccination/main/services/createEditVaccineApi';

const useCreateEditVaccine = () => {
  const queryClient = useQueryClient();
  const location = useLocation() as Location & { state: { vaccineId?: string } };
  const vaccineId = location.state?.vaccineId;
  const mutation = useMutation<AddVaccinationType, Error, AddVaccination | Partial<NewVaccination>>({
    mutationFn: (values: AddVaccination | Partial<NewVaccination>) => createEditVaccine(values, vaccineId),
    onSuccess: async () => {
      let successMsg = 'Vaccine created successfully';
      if (vaccineId) {
        successMsg = 'Vaccine saved successfully';
      }
      toast.success(successMsg);
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['vaccines'] }),
        queryClient.invalidateQueries({ queryKey: ['vaccine'] }),
      ]);
    },
    onError: () => {
      toast.error(`Error creating Vaccine`);
    },
  });

  return { ...mutation, isLoading: mutation.isPending };
};

export default useCreateEditVaccine;
