import { makeRequest } from '@api/api';
import { GET_VACCINATION_HISTORY } from '@api/urls/VaccinationUrls';
import { Record } from '@models/classes/VaccinationHistory/Record';

const getVaccinationHistory = async (nhsNumber: string, targetDisease: string): Promise<Record[]> => {
  const apiPath = GET_VACCINATION_HISTORY.replace('{nhsNumber}', nhsNumber).replace(
    '{targetDisease}',
    targetDisease,
  );
  const data = await makeRequest<Record[]>({
    method: 'GET',
    path: apiPath,
  });
  return data.sort(
    (a, b) => new Date(b.administeredDate ?? '').getTime() - new Date(a.administeredDate ?? '').getTime(),
  );
};

export default getVaccinationHistory;
