import React, { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import ConfirmationModal from './ConfiramtionModal';
import ConfirmationModalContext from '@context/ConfirmationModalContext';

const ConfirmationModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [modalProps, setModalProps] = useState<React.ComponentProps<typeof ConfirmationModal> | null>(null);

  const displayModal = (
    props: Omit<React.ComponentProps<typeof ConfirmationModal>, 'isOpen' | 'closeModal'>,
  ) => {
    setModalProps({
      ...props,
      isOpen: true,
      closeModal: () => setModalProps(null),
    });
  };
  const contextValue = useMemo(() => ({ displayModal }), []);

  return (
    <ConfirmationModalContext.Provider value={contextValue}>
      {children}
      {modalProps &&
        ReactDOM.createPortal(
          <ConfirmationModal {...modalProps} closeModal={() => setModalProps(null)} />,
          document.body,
        )}
    </ConfirmationModalContext.Provider>
  );
};

export default ConfirmationModalProvider;
