import { createContext, ReactNode } from 'react';
import { Response as PatientDemographics } from '@models/classes/PatientDetailsSideBar/PatientDemographicsResponse';

export interface VaccinationType {
  value: string;
  label: string;
}

export const PATIENT_SEARCH = 'patient-search';
export const VACCINATION_TYPE = 'vaccination-type';
export const DELIVERY_SCREENING = 'delivery-screening';

export type FormStep = typeof PATIENT_SEARCH | typeof VACCINATION_TYPE | typeof DELIVERY_SCREENING;

export type FormStepObj = {
  key: string;
  value: string;
  id: number;
  active: boolean;
  component: ReactNode;
};

export interface VaccinationFormValues {
  selectedPatient?: PatientDemographics;
  selectedVaccination?: VaccinationType;
  clinicalScreening?: Record<string, any>;
  deliveryDetails?: Record<string, any>;
}

// Context props interface
export interface AddVaccinationsContextType {
  formStep: FormStep;
  formSteps?: FormStepObj[];
  formValues: VaccinationFormValues;
  availableVaccinations: VaccinationType[];
  setFormStep: (step: FormStep) => void;
  setFormSteps: (step: FormStepObj[]) => void;
  activateNewStep: (step: FormStepObj[]) => void;
  setPatient: (patient: PatientDemographics) => void;
  setAvailableVaccinations: (vaccinations: VaccinationType[]) => void;
  resetForm: () => void;
  isLoading: boolean;
}

export const AddVaccinationsContext = createContext<AddVaccinationsContextType>({
  formStep: PATIENT_SEARCH,
  formValues: {},
  availableVaccinations: [],
  setFormStep: () => {},
  setFormSteps: () => {},
  activateNewStep: () => {},
  setPatient: () => {},
  setAvailableVaccinations: () => {},
  resetForm: () => {},
  isLoading: false,
});
