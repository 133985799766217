import React, { createContext } from 'react';
import ConfirmationModal from '@components/Shared/Confirmation/ConfiramtionModal';

interface ConfirmationModalContextProps {
  displayModal: (
    props: Omit<React.ComponentProps<typeof ConfirmationModal>, 'isOpen' | 'closeModal'>,
  ) => void;
}

const ConfirmationModalContext = createContext<ConfirmationModalContextProps | undefined>(undefined);

export default ConfirmationModalContext;
