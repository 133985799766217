import ApiRequest from '@models/common/ApiRequest';
import { ContentType } from '@enums/ContentTypeEnum';
import { v4 as uuidv4 } from 'uuid';
import KeyCloakAuth from '@security/KeyCloakAuth';
import { getLocumInfo } from '@components/InterceptLocumUser/InterceptLocumUser';
import { ResponseError } from '@appTypes/ResponseError';

export async function makeRequest<T>(requestOptions: ApiRequest): Promise<T> {
  const serverUrl = ''; //import.meta.env.MODE === 'development' ? import.meta.env.VITE_DEV_API_URL: import.meta.VITE_PROD_API_URL
  // fetch above from .env files

  const path = requestOptions.isAbsoultePath ? requestOptions.path : `${serverUrl}/${requestOptions.path}`;
  const correlationId = uuidv4();
  const locumInfo = getLocumInfo();

  const options = {
    method: requestOptions.method,
    headers: {
      'Content-Type': requestOptions.contentType ? requestOptions.contentType : ContentType.JSON,
      'x-correlation-id': correlationId,
      'x-locum-first-name': locumInfo !== null ? locumInfo.firstName : '',
      'x-locum-last-name': locumInfo !== null ? locumInfo.lastName : '',
      'x-locum-gphc-number': locumInfo !== null ? locumInfo.gphcNumber : '',
      Authorization: 'Bearer ' + (await KeyCloakAuth.getToken()),
    },

    body: undefined,
  };

  if (requestOptions.data) {
    options.body =
      requestOptions.contentType === ContentType.TEXT
        ? requestOptions.data
        : JSON.stringify(requestOptions.data);
  }
  let apiUrl = path;
  if (
    import.meta.env?.VITE_ENV === 'staging' &&
    import.meta.env.VITE_API_URL &&
    !requestOptions.isAbsoultePath
  ) {
    apiUrl = `${import.meta.env.VITE_API_URL}${path}`;
  }

  const response = await fetch(apiUrl, options);

  let returnValue: any | null = null;
  try {
    returnValue = await parseResponse(response, path, options);
  } catch (e) {
    //    log('Error', `API: Unable to parse response for: ${response.status} ${requestOptions.method} ${path} (cid:${correlationId} sid:${sessionId})`);
  }
  if (response.status < 200 || response.status >= 400) {
    throw new ResponseError(response.status.toString(), {
      cause: returnValue?.message || response.status,
    });
  }
  return returnValue;
}

async function parseResponse(response: any, path: string, options: any) {
  const text = await response.text();

  if (text) {
    try {
      let apiResponse;
      const json = JSON.parse(text);
      if (json) {
        apiResponse = json;
        return apiResponse;
      } else {
        return null;
      }
    } catch (e) {
      console.log(`API: Unable to parse response for ${response.status} ${options.method} ${path}`);
      return null;
    }
  } else {
    return {};
  }
}
