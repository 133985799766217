import { Button, Modal, ModalContent, ModalFooter, Typography } from '@crx-dev/hss-react-components';
import React from 'react';

interface ConfirmationModalProps {
  title: string;
  body: string;
  isOpen: boolean;
  confirmButtonLabel?: string;
  closeModal: () => void;
  confirm?: () => void;
  closeButtonLabel: string;
}
const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  body,
  title,
  isOpen,
  confirmButtonLabel,
  closeModal,
  confirm,
  closeButtonLabel,
}) => {
  return (
    <Modal title={title} open={isOpen} onCancel={closeModal}>
      <ModalContent>
        <Typography>{body}</Typography>
      </ModalContent>
      <ModalFooter>
        {/*@ts-expect-error to be solved by storybook*/}
        <Button id="close-btn" onClick={closeModal} variant="secondary">
          {closeButtonLabel}
        </Button>
        {confirmButtonLabel && (
          // @ts-expect-error to be solved by storybook
          <Button
            id="confirm-btn"
            onClick={() => {
              confirm?.();
              closeModal();
            }}
            variant="primary"
          >
            {confirmButtonLabel}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
