import { useContext } from 'react';
import ConfirmationModalContext from '@context/ConfirmationModalContext';

const useConfirmationModal = () => {
  const context = useContext(ConfirmationModalContext);
  if (!context) {
    throw new Error('useConfirmationModal must be used within a ConfirmationModalProvider');
  }
  return context;
};

export default useConfirmationModal;
