import React, { useMemo, ReactNode, useCallback, useReducer, useState } from 'react';
import {
  AddVaccinationsContext,
  FormStep,
  VaccinationType,
  FormStepObj,
  VACCINATION_TYPE,
  DELIVERY_SCREENING,
} from '@Vaccination/main/context/AddVaccinationsContext';
import { Response as PatientDemographics } from '@models/classes/PatientDetailsSideBar/PatientDemographicsResponse';
import { AddVaccination, getStepValidationScheme } from '@Vaccination/utils/AddVaccinationScheme';
import { Formik } from 'formik';
import FormikResetHandler from '@Vaccination/main/context/FormikResetHandler';
import useConfirmationModal from '@src/hooks/useConfirmationModal';
import { reducer, initialState } from '@Vaccination/utils/AddVaccinationsReducer';
import useCreateEditVaccine from '@Vaccination/main/hooks/useCreateEditVaccine';
import { useNavigate } from 'react-router-dom-v6';
import { VACCINATION_PATHS } from '@enums/PagesPathsEnum';
import { createVaccinationSteps, moveToNextStep } from '@Vaccination/utils/helpers';

const AddVaccinationsProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { displayModal } = useConfirmationModal();
  const { mutate } = useCreateEditVaccine();
  const navigate = useNavigate();

  const setFormStep = useCallback((step: FormStep) => {
    dispatch({ type: 'SET_FORM_STEP', payload: step });
  }, []);

  const setFormSteps = useCallback((step: FormStepObj[]) => {
    dispatch({ type: 'SET_FORM_STEPS', payload: step });
  }, []);

  const activateNewStep = useCallback((step: FormStepObj[]) => {
    dispatch({ type: 'ACTIVATE_NEW_STEP', payload: step });
  }, []);

  const setPatient = useCallback((patient: PatientDemographics) => {
    dispatch({ type: 'SET_PATIENT', payload: patient });
  }, []);

  const setAvailableVaccinations = useCallback((vaccinations: VaccinationType[]) => {
    dispatch({ type: 'SET_AVAILABLE_VACCINATIONS', payload: vaccinations });
  }, []);

  const resetForm = useCallback(() => {
    dispatch({ type: 'RESET_FORM' });
  }, []);

  const handleEpisodeClosure = useCallback(() => {
    const data = {
      consentStatus: false,
      status: 'Closed',
      patientDemographics: state.formValues.selectedPatient,
    };
    return displayModal({
      title: 'Closure Confirmation',
      body: "Are you sure you want to close this patient's episode? You will be unable to edit or add to the episode once closed",
      confirmButtonLabel: 'Close',
      closeButtonLabel: 'Cancel',
      confirm: () => mutate(data),
    });
  }, [displayModal, mutate, state.formValues.selectedPatient]);

  const handleSubmit = (values: AddVaccination) => {
    if (values.consentStatus === false) {
      return handleEpisodeClosure();
    }
    setIsLoading(true);
    mutate(
      {
        ...values,
        currentStep: state.formStep,
        patientDemographics: state.formValues.selectedPatient,
      },
      {
        onSuccess: (data, variables) => {
          navigate(VACCINATION_PATHS.ADD_VACCINATION, {
            state: { vaccineId: data.id },
          });

          if (data.currentStep === VACCINATION_TYPE && variables.vaccineTypes?.length) {
            const steps = createVaccinationSteps(variables.vaccineTypes);
            setFormSteps(steps);
            return;
          }

          // Handle delivery screening steps
          if (data.currentStep === DELIVERY_SCREENING && state.formSteps?.length) {
            const activeStepIndex = state.formSteps.findIndex(step => step.active);

            // If there are more steps, move to the next one
            if (activeStepIndex < state.formSteps.length - 1) {
              const nextSteps = moveToNextStep(state.formSteps);
              activateNewStep(nextSteps);
              return;
            }

            // Otherwise submit the form
            // submitVaccinationData(values);
            return;
          }

          // Default action - move to delivery screening
          setFormStep(DELIVERY_SCREENING);
        },
        onSettled: () => {
          setIsLoading(false);
        },
      },
    );
  };

  const contextValue = useMemo(
    () => ({
      formStep: state.formStep,
      formSteps: state.formSteps,
      formValues: state.formValues,
      availableVaccinations: state.availableVaccinations,
      setFormStep,
      setFormSteps,
      activateNewStep,
      setPatient,
      setAvailableVaccinations,
      resetForm,
      isLoading,
    }),
    [
      resetForm,
      setAvailableVaccinations,
      setFormStep,
      setFormSteps,
      activateNewStep,
      setPatient,
      state.availableVaccinations,
      state.formStep,
      state.formSteps,
      state.formValues,
      isLoading,
    ],
  );

  return (
    <AddVaccinationsContext.Provider value={contextValue}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={new AddVaccination()}
        validationSchema={getStepValidationScheme(state.formStep)}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <>
          <FormikResetHandler />
          {children}
        </>
      </Formik>
    </AddVaccinationsContext.Provider>
  );
};

export default AddVaccinationsProvider;
