import { AddVaccination, NewVaccination, AddVaccinationType } from '@Vaccination/utils/AddVaccinationScheme';
import { makeRequest } from '@api/api';

const createEditVaccine = async (
  data: AddVaccination | Partial<NewVaccination>,
  vaccineId?: string,
): Promise<AddVaccinationType> => {
  let apiPath = `vaccination/episode`;
  if (vaccineId) {
    apiPath += `?episodeId=${vaccineId}`;
  }
  return makeRequest({
    method: 'PUT',
    path: apiPath,
    data,
  });
};

export default createEditVaccine;
