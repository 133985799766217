import { useCallback, useState, useMemo } from 'react';
import * as api from '@api/api';
import { instanceToInstance, plainToClass, plainToInstance } from 'class-transformer';

export interface ApiRequestActions<T> {
  makeRequest: (data?: any) => void;
  setData: (data: T) => void;
  clearData: () => void;
  setUrl: (newPath: string) => void;
  makePromiseRequest: (newPath?: string, data?: any) => Promise<T>;
  makeArrayPromiseRequest: (data?: any) => Promise<Array<T>>;
}

export default function useApiRequestNew<T>(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  initialPath: string,
  type: any,
  isAbsolutePath?: boolean,
  contentType?: string,
): [T, ApiRequestActions<T>, RequestStatus] {
  const [response, setResponse] = useState<T>([] as unknown as T);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>({
    isPending: false,
    hasError: false,
    message: '',
  });
  const [path, setPath] = useState<string>(initialPath);

  const setNewPath = useCallback((newPath: string) => {
    setPath(newPath);
  }, []);

  const clearResponseData = useCallback(() => {
    setResponse(null as unknown as T);
  }, []);

  const makeRequest = useCallback(
    async (data?: any) => {
      try {
        setRequestStatus({
          isPending: true,
          hasError: false,
          message: 'Calling api',
        });

        const response = await api.makeRequest({
          method,
          path,
          data,
          isAbsoultePath: isAbsolutePath,
          contentType,
        });

        if (response) {
          setResponse(plainToClass(type, response as T));
        }

        setRequestStatus({ isPending: false, hasError: false, message: '' });
      } catch (error) {
        setRequestStatus({
          isPending: false,
          hasError: true,
          message: 'Could not complete request',
        });
      }
    },
    [method, path, isAbsolutePath, contentType, type],
  );

  const makePromiseRequest = useCallback(
    async (newPath?: string, data?: any): Promise<T> => {
      try {
        setRequestStatus({
          isPending: true,
          hasError: false,
          message: 'Calling api',
        });

        const response = await api.makeRequest({
          method,
          path: newPath ?? path,
          data,
          isAbsoultePath: isAbsolutePath,
          contentType,
        });

        if (response) {
          const responseClassForm: T = plainToInstance(type, response as T);
          setRequestStatus({ isPending: false, hasError: false, message: '' });
          return responseClassForm;
        }

        throw new Error('Error parsing the returned result into the desired class');
      } catch (error) {
        setRequestStatus({
          isPending: false,
          hasError: true,
          message: 'Could not complete request',
        });
        throw error;
      }
    },
    [method, path, isAbsolutePath, contentType, type],
  );

  const makeArrayPromiseRequest = useCallback(
    async (data?: any): Promise<Array<T>> => {
      try {
        setRequestStatus({
          isPending: true,
          hasError: false,
          message: 'Calling api',
        });

        const response = await api.makeRequest<T>({
          method,
          path,
          data,
          isAbsoultePath: isAbsolutePath,
          contentType,
        });

        if (response) {
          const responseClassForm: Array<T> = instanceToInstance(response as Array<T>);
          setRequestStatus({ isPending: false, hasError: false, message: '' });
          return responseClassForm;
        }

        throw new Error('Error parsing the returned result into the desired class');
      } catch (error) {
        setRequestStatus({
          isPending: false,
          hasError: true,
          message: 'Could not complete request',
        });
        throw error;
      }
    },
    [method, path, isAbsolutePath, contentType],
  );

  const actions = useMemo(
    () => ({
      makeRequest,
      setData: setResponse,
      clearData: clearResponseData,
      setUrl: setNewPath,
      makePromiseRequest,
      makeArrayPromiseRequest,
    }),
    [makeRequest, clearResponseData, setNewPath, makePromiseRequest, makeArrayPromiseRequest],
  );

  return [response, actions, requestStatus];
}

/**
 * Example Usage
 *   const [patientNhsDetailsAction, setPatientNhsDetailsAction] = useApiRequestNew<NhsPatientDetailsResponse>
 *     ('GET', PDS_GET_PATIENT_DETAILS, NhsPatientDetailsResponse);
 *
 *     setPatientNhsDetailsAction.setUrl(searchPatientDetails);
 *     setPatientNhsDetailsAction
 *       .makePromiseRequest(searchPatientDetails)
 *       .then((result: NhsPatientDetailsResponse) => {
 *         parsePatientDetailsResult(result);
 *         if (result.supersededNhsNumber) {
 *           setIsSupersededNhsNumber(true);
 *         }
 *         setPatientNhsDetailsAction.setData(result);
 *       })
 *       .catch(() => {
 *         setIsPdsSearchError(true);
 *       })
 *       .finally(() => {
 *         setIsLoadingPdsSearch(false);
 *       });
 * */
