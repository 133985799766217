import { Heading } from '@crx-dev/hss-react-components';
import React from 'react';
import PreviousVaccinationCard from '@Vaccination/main/components/VaccinationHistoryCard';

const RenderVaccineComponent = ({
  vaccineType,
  componentType,
}: {
  vaccineType: string;
  componentType: 'screening' | 'delivery';
}) => {
  if (vaccineType === 'Flu Vac') {
    return componentType === 'screening' ? (
      <>
        <PreviousVaccinationCard />
      </>
    ) : (
      <Heading>Flu Delivery</Heading>
    );
  } else if (vaccineType === 'Test new vaccineType') {
    return componentType === 'screening' ? (
      <Heading>new vaccine Screening</Heading>
    ) : (
      <Heading>new vaccine Delivery</Heading>
    );
  }
  // Default case or error handling
  return <div>Unknown vaccine type: {vaccineType}</div>;
};

export default RenderVaccineComponent;
