import * as yup from 'yup';
import { ValidationErrorsMsg } from '@enums/ValidationErrors';

export const LocumDetailsSchema = yup.object().shape({
  firstName: yup.string().required(ValidationErrorsMsg.REQUIRED_MESSAGE),
  lastName: yup.string().required(ValidationErrorsMsg.REQUIRED_MESSAGE),
  gphcNumber: yup
    .string()
    .required(ValidationErrorsMsg.REQUIRED_MESSAGE)
    .matches(/^[0-9]+$/, 'Field must be a number')
    .min(7, 'Must be exactly 7 characters')
    .max(7, 'Must be exactly 7 characters'),
});
